export type FeatureKeyIdentifier = keyof typeof FEATURE_FLAGS;
export enum FEATURE_FLAGS {
    // XE-3 - Optimizely Spike
    ALTERNATE_SIDE_NAVIGATION = 'policy-management_feature_alternate-side-navigation',
    // Auto-Refresh Case Details for demo
    AUTO_REFRESH_CASE_DETAILS = 'auto-refresh-case-details',
    // DEPU-2043 - Policy Management
    POLICY_MANAGEMENT_ENABLED = 'policy-management_feature_policy_mgmt_main_nav_link',
    // DEPU-2137 Case Management Case Redesign
    CASE_MANAGEMENT_CASE_REDESIGN_ENABLED = 'case-management-depu-1791_case_redesign',
    POLICY_MANAGEMENT_ANNUITIES_ENABLED = 'policy-management_feature_policy_mgmt_annuities',
    // CMW-14579 - Regulation 60
    REG_60 = 'case-management-otp_feature_regulation-60',
    SSW_MASS = 'case-management-otp_feature_mass_ssw',
    SSW_SBGC = 'case-management-otp_feature_sbgc_ssw',
    SSW_NASU = 'case-management-otp_feature_nasu_ssw',
    SSW_FLIC = 'case-management-otp_feature_flic_ssw',
    SSW_GLCO = 'case-management-otp_feature_glco_ssw',
    SSW_ULPC = 'case-management-otp_feature_ulpc_ssw',
    SSW_RSLN = 'case-management-otp_feature_rsln_ssw',
    SSW_PRDN = 'case-management-otp_feature_prdn_ssw',
    SSW_GDMN = 'case-management-otp_feature_gdmn_ssw',
    SSW_USAA = 'case-management-otp_feature_usaa_ssw',
    OFT_MASS = 'case-management-otp_feature_mass_oft',
    OFT_SBGC = 'case-management-otp_feature_sbgc_oft',
    OFT_DLIC = 'case-management-otp_feature_dlic_oft',
    OFT_RSLN = 'case-management-otp_feature_rsln_oft',
    OFT_FLIC = 'case-management-otp_feature_flic_oft',
    OFT_GLCO = 'case-management-otp_feature_glco_oft',
    OFT_GDMN = 'case-management-otp_feature_gdmn_oft',
    OFT_USAA = 'case-management-otp_feature_usaa_oft',
    OFT_NASU = 'case-management-otp_feature_nasu_oft',
    OFT_ULPC = 'case-management-otp_feature_ulpc_oft',
    OFT_PRDN = 'case-management-otp_feature_prdn_oft',
    NEW_EXP = 'case-management-otp_feature_new_experience',
    TRANSACTION_HISTORY = 'case-management-otp_feature_transaction_history',
    WITHDRAWAL_DLIC = 'case-management-otp_feature_dlic_withdrawal',
    WITHDRAWAL_FLIC = 'case-management-otp_feature_flic_withdrawal',
    WITHDRAWAL_GDMN = 'case-management-otp_feature_gdmn_withdrawal',
    WITHDRAWAL_MASS = 'case-management-otp_feature_mass_withdrawal',
    WITHDRAWAL_NASU = 'case-management-otp_feature_nasu_withdrawal',
    WITHDRAWAL_RSLN = 'case-management-otp_feature_rsln_withdrawal',
    WITHDRAWAL_SBGC = 'case-management-otp_feature_sbgc_withdrawal',
    WITHDRAWAL_GLCO = 'case-management-otp_feature_glco_withdrawal',
    WITHDRAWAL_ULPC = 'case-management-otp_feature_ulpc_withdrawal',
    SEND_DOCUMENT = 'contact-center-send-document',
    ADDRESS_CHANGE_SBGC = 'case-management-otp-feature-sbgc-address-change',
    ADDRESS_CHANGE_MASS = 'case-management-otp-feature-mass-address-change',
    ADDRESS_CHANGE_DLIC = 'case-management-otp-feature-dlic-address-change',
    ADDRESS_CHANGE_FLIC = 'case-management-otp-feature-flic-address-change',
    ADDRESS_CHANGE_NASU = 'case-management-otp-feature-nasu-address-change',
    ADDRESS_CHANGE_ULPC = 'case-management-otp-feature-ulpc-address-change',
    ADDRESS_CHANGE_GLCO = 'case-management-otp-feature-glco-address-change',
    ADDRESS_CHANGE_PRDN = 'case-management-otp-feature-prdn-address-change',
    ADDRESS_CHANGE_GDMN = 'case-management-otp-feature-gdmn-address-change',
    ADDRESS_CHANGE_USAA = 'case-management-otp-feature-usaa-address-change',
    ADDRESS_CHANGE_RSLN = 'case-management-otp-feature-rsln-address-change',
    ADDRESS_CHANGE_PRUD = 'case-management-otp-feature-prud-address-change',
    ADDRESS_CHANGE_ALLM = 'case-management-otp-feature-allm-address-change',
    SEND_DOCUMENT_SHOW_CASE_BUTTON = 'contact-center-send-document-show-case',
    REREG_FLIC = 'case-management-otp_feature_flic_rereg',
    REREG_MASS = 'case-management-otp_feature_mass_rereg',
    REREG_SBGC = 'case-management-otp_feature_sbgc_rereg',
    SEND_DOCUMENT_SHOW_MAIL_OPTION = 'contact-center-send-document-show-mail-option',
    REVERSE_RECREATE_ENABLED = 'policy-management-reverse_recreate',
    NIGO_ENTRY_SBGC_WITHDRAWAL = 'nigo-entry_sbgc_withdrawal',
    NIGO_ENTRY_FLIC_WITHDRAWAL = 'nigo-entry_flic_withdrawal',
    NIGO_ENTRY_NASU_WITHDRAWAL = 'nigo-entry_nasu_withdrawal',
    NIGO_ENTRY_DLIC_WITHDRAWAL = 'nigo-entry_dlic_withdrawal',
    NIGO_ENTRY_MASS_WITHDRAWAL = 'nigo-entry_mass_withdrawal',
    NIGO_ENTRY_RSLN_WITHDRAWAL = 'nigo-entry_rsln_withdrawal',
    NIGO_ENTRY_GDMN_WITHDRAWAL = 'nigo-entry_gdmn_withdrawal',
    NIGO_ENTRY_ULPC_WITHDRAWAL = 'nigo-entry_ulpc_withdrawal',
    NIGO_ENTRY_GLCO_WITHDRAWAL = 'nigo-entry_glco_withdrawal',
    NIGO_ENTRY_SBGC_OFT = 'nigo-entry_sbgc_oft',
    NIGO_ENTRY_FLIC_OFT = 'nigo-entry_flic_oft',
    NIGO_ENTRY_DLIC_OFT = 'nigo-entry_dlic_oft',
    NIGO_ENTRY_MASS_OFT = 'nigo-entry_mass_oft',
    NIGO_ENTRY_RSLN_OFT = 'nigo-entry_rsln_oft',
    NIGO_ENTRY_GLCO_OFT = 'nigo-entry_glco_oft',
    NIGO_ENTRY_USAA_OFT = 'nigo-entry_usaa_oft',
    NIGO_ENTRY_NASU_OFT = 'nigo-entry_nasu_oft',
    NIGO_ENTRY_ULPC_OFT = 'nigo-entry_ulpc_oft',
    NIGO_ENTRY_GDMN_OFT = 'nigo-entry_gdmn_oft',
    NIGO_ENTRY_PRDN_OFT = 'nigo-entry_prdn_oft',
    NIGO_ENTRY_SBGC_SSW = 'nigo-entry_sbgc_ssw',
    NIGO_ENTRY_FLIC_SSW = 'nigo-entry_flic_ssw',
    NIGO_ENTRY_MASS_SSW = 'nigo-entry_mass_ssw',
    NIGO_ENTRY_ULPC_SSW = 'nigo-entry_ulpc_ssw',
    NIGO_ENTRY_NASU_SSW = 'nigo-entry_nasu_ssw',
    NIGO_ENTRY_GLCO_SSW = 'nigo-entry_glco_ssw',
    NIGO_ENTRY_RSLN_SSW = 'nigo-entry_rsln_ssw',
    NIGO_ENTRY_PRDN_SSW = 'nigo-entry_prdn_ssw',
    NIGO_ENTRY_GDMN_SSW = 'nigo-entry_gdmn_ssw',
    NIGO_ENTRY_USAA_SSW = 'nigo-entry_usaa_ssw',
    NIGO_ENTRY_SBGC_RMD = 'nigo-entry_sbgc_rmd',
    NIGO_ENTRY_FLIC_RMD = 'nigo-entry_flic_rmd',
    NIGO_ENTRY_MASS_RMD = 'nigo-entry_mass_rmd',
    NIGO_ENTRY_GLCO_RMD = 'nigo-entry_glco_rmd',
    NIGO_ENTRY_ULPC_RMD = 'nigo-entry_ulpc_rmd',
    NIGO_ENTRY_RSLN_RMD = 'nigo-entry_rsln_rmd',
    NIGO_ENTRY_GDMN_RMD = 'nigo-entry_gdmn_rmd',
    NIGO_ENTRY_DLIC_RMD = 'nigo-entry_dlic_rmd',
    NIGO_ENTRY_USAA_RMD = 'nigo-entry_usaa_rmd',
    NIGO_ENTRY_NASU_RMD = 'nigo-entry_nasu_rmd',
    NIGO_ENTRY_PRDN_RMD = 'nigo-entry_prdn_rmd',
    SEND_DOCUMENT_HIDE_MAIL_OPTION_ILNA = 'contact-center-send-document-arcus-mail-option',
    SEND_STATEMENT_EMAIL_MASS = 'contact-center-send-statement-email-mass',
    SEND_STATEMENT_EMAIL_DLIC = 'contact-center-send-statement-email-dlic',
    SEND_STATEMENT_EMAIL_NASU = 'contact-center-send-statement-email-nasu',
    SEND_STATEMENT_FAX_MASS = 'contact-center-send-statement-fax-mass',
    SEND_STATEMENT_FAX_DLIC = 'contact-center-send-statement-fax-dlic',
    SEND_STATEMENT_FAX_GDMN = 'contact-center-send-statement-fax-gdmn',
    SEND_STATEMENT_FAX_NASU = 'contact-center-send-statement-fax-nasu',
    SEND_STATEMENT_FAX_PRDN = 'contact-center-send-statement-fax-prdn',
    SEND_STATEMENT_FAX_PRUD = 'contact-center-send-statement-fax-prud',
    SEND_STATEMENT_FAX_FLIC = 'contact-center-send-statement-fax-flic',
    SEND_STATEMENT_FAX_GLAC = 'contact-center-send-statement-fax-glac',
    SEND_STATEMENT_FAX_JHLI = 'contact-center-send-statement-fax-jhli',
    SEND_STATEMENT_MAIL_SBGC = 'contact-center-send-statement-mail-sbgc',
    SEND_STATEMENT_MAIL_MASS = 'contact-center-send-statement-mail-mass',
    SEND_STATEMENT_MAIL_DLIC = 'contact-center-send-statement-mail-dlic',
    SEND_STATEMENT_MAIL_GDMN = 'contact-center-send-statement-mail-gdmn',
    SEND_STATEMENT_MAIL_ULPC = 'contact-center-send-statement-mail-ulpc',
    SEND_STATEMENT_MAIL_RSLN = 'contact-center-send-statement-mail-rsln',
    SEND_STATEMENT_MAIL_GLCO = 'contact-center-send-statement-mail-glco',
    SEND_STATEMENT_MAIL_NASU = 'contact-center-send-statement-mail-nasu',
    SEND_STATEMENT_MAIL_USAA = 'contact-center-send-statement-mail-usaa',
    SEND_STATEMENT_MAIL_PRDN = 'contact-center-send-statement-mail-prdn',
    SEND_STATEMENT_MAIL_PRUD = 'contact-center-send-statement-mail-prud',
    SEND_STATEMENT_MAIL_FLIC = 'contact-center-send-statement-mail-flic',
    SEND_STATEMENT_MAIL_GLAC = 'contact-center-send-statement-mail-glac',
    SEND_STATEMENT_MAIL_EMRS = 'contact-center-send-statement-mail-emrs',
    SEND_STATEMENT_MAIL_SAAG = 'contact-center-send-statement-mail-saag',
    SEND_STATEMENT_MAIL_JHLI = 'contact-center-send-statement-mail-jhli',
    CASE_STATS_DASHBOARD = 'case-management-case_stats_dashboard',
    CASE_INSIGHTS = 'case_insights',
    POLICY_FREE_LOOK_CANCELLATION = 'policy-management_feature_free-look-cancellation',
    SEND_TAX_FORMS = 'contact-center-send-taxforms',
    SEND_TAX_FORMS_SHOW_CASE_BUTTON = 'contact-center-send-taxforms-show-case-button',
    POM_EXPERIENCE = 'pom-experience',
    DOCUMENTS_V3 = 'documents-v3-update',
    CASE_ADVISORS_EXCEL_ADDITIONAL_CARRIER_SUPPORT = 'case_advisors_excel_additional_carrier_support',
    DASHBOARD_CASE_TIMING_CHART = 'dashboard_case_timing_chart',
    LOAN_PAYMENT_TRANSACTION = 'loan_payment_transaction',
    DASHBOARD_SECTION_TAB_VIEW = 'dashboard_section_tab_view',
}
